.action-pills {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 50px;
    vertical-align: middle;
}

.action-pills:first-child {
    border-left: .5px solid rgb(216, 216, 216);
}

.pill {
    background: rgb(255, 255, 255);
    cursor: pointer;
    padding: 0 16px;
    height: 50px !important;
    line-height: 50px;
    border-right: .5px solid rgb(216, 216, 216);
    border-top: .5px solid rgb(216, 216, 216);
    border-bottom: .5px solid rgb(216, 216, 216);
}

.selected {
    background: rgb(0, 122, 255);
    color: rgb(255, 255, 255);
    padding: 0 16px;
    height: 50px !important;
    line-height: 50px;
}

:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.sample-capture-modal {
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.sample-capture-modal .sample-capture-modal-wrap {
    bottom: 0;
    left: 0;
    outline: 0;
    overflow: auto;
    padding: 48px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.sample-capture-modal .sample-capture-modal-wrap .inner {
    position: relative;
}

.capture-image {
    max-height: 100%;
    max-width: 100%;
}

/* .close-circle, */
.close-icon {
    position: absolute !important;
    right: 28px !important;
    top: 28px !important;
    z-index: 1002 !important;
}

.sample-capture-action-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.sample-capture-action-wrapper>div {
    margin-right: 16px;
    width: 100%;
}

.sample-capture-action-wrapper>div:last-child {
    margin-right: 0;
}

@media only screen and (max-width:767px) {
    .sample-capture-action-wrapper {
        flex-direction: column;
    }

    .sample-capture-action-wrapper>div {
        margin-bottom: 16px;
        margin-right: 0;
    }

    .sample-capture-action-wrapper>div:last-child {
        margin-bottom: 0;
    }
}

.divider {
    background-color: rgb(232, 232, 232);
    height: 20px;
    margin: 0px 16px;
    width: 1px;
}

.auto-photo-controls-div {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 10px;
}

.seconds-input {
    position: relative;
    align-items: center;
}

.seconds-label {
    color: grey;
    position: absolute;
    right: 0;
    bottom: 8px;
    padding-right: 2cqb;
}

.sample-capture-button-container {
    align-items: center;
    background: rgb(255, 255, 255);
    bottom: 0;
    box-shadow: rgba(0, 21, 41, 0.08) 0px -1px 3px 0px;
    display: flex;
    height: 64px;
    left: 0;
    position: fixed;
    right: 0;
}

.sample-capture-button-container .sample-capture-buttons {
    display: flex;
    padding: 0 16px;
    width: 100%;
}

.sample-capture-button-container .sample-capture-buttons>button {
    height: 40px;
    justify-content: center;
    width: 100%;
}

.sample-capture-button-container .sample-capture-buttons>button:first-child {
    margin-right: 16px;
    width: 25%;
}

.capture-image-button {
    margin-left: 20px;
}

.card {
    background: #FEFEFE;
    border-radius: 9px;
    border-color: lightgray;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 98vw;
}

.green {
    background-color: green;
    color: white;
    border: none !important;
    border-radius: 4px;
}

.disabled-submit-button{
    background: rgb(123, 130, 138);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 4px;
    height: 40px;
}

.submit-button {
    background: rgb(0, 122, 255);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 4px;
    height: 40px;
}

.submit-button-archive {
    width: 100px;
}

.secondary {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(216, 216, 216);
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
}

.card-body {
    margin-right: 0 !important;
    width: 100%;
}

.action-pills {
    margin-top: 60px;
}

.tab-content {
    margin-bottom: 80px;
}

.capture-form {
    width: 100%;
    padding: 0;
}

.archive-search-wrapper {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 16px;
}

.range-toggle-button {
    width: 100px;
    margin-right: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 4px;
    height: 40px;
    white-space: nowrap;
}

.input-field {
    margin-left: 10px;
    width: 100%;
}

.input-field:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid lightgray;
}

.labStateBtn {
    width: auto;
    /* margin-left: 18px; */
    border: none;
    background-color: inherit;
    text-align: center;
}

.sampleTypeStateBtn {
    width: auto;
    /* margin-left: 100px; */
    border: none;
    background-color: inherit;
}

.reset-filter-btn {
    border: none;
    background-color: inherit;
    color: blue;
    /* margin-left: 213px; */
    /* padding-top: 30px; */
}

.reset-filter-btn:hover {
    color: darkblue;
}

.table-head, .table-row {
    align-items: center;
    border-bottom: 1px solid rgb(240, 240, 240);
    display: flex;
}

.table-head>div, .table-row>div {
    padding: 16px;
    width: 25%;
}

.table-head{
    background: rgb(250, 250, 250);
    color: rgb(0, 0, 0);
}

.table-row-extra {
    border-bottom: 1px solid rgb(240, 240, 240);
    padding: 16px;
}

.table-row .action-col {
    align-items: center;
    color: rgb(0, 122, 255);
    display: flex;
    justify-content: flex-end;
}

.table-row .action-col .photo-action {
    cursor: pointer;
}

.photo-action {
    align-items: center;
    display: flex;
}

.table-row .action-col .photo-action img {
    margin-left: 4px;
}

.table-row .action-col .divider {
    background: rgb(216, 216, 216);
    height: 16px;
    margin: 0 8px;
    width: 1px;
}

.edit-sample-capture-buttons {
    align-items: center;
    display: flex;
    margin-top: 16px;
    width: 100%;
}

.edit-sample-capture-buttons>button {
    height: 40px;
    justify-content: center;
    width: 100%;
}

.edit-sample-capture-buttons>button:first-child {
    margin-right: 16px;
    width: 25%;
}